import React, { Component } from "react";
import { graphql } from "gatsby";
import "antd/dist/antd.css";
import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import HomeHeader from "../components/home/Header";
import FeatureHeader from "../components/features/Header";
import Slices from "../components/shared/Slices";
import { getGeoInfoFromIp } from "../utilities/utils";
import { countryCodes } from "../constants";
class IndexPage extends Component {
  state = {
    countryCode: "",
    loading: true,
  };

  componentDidMount() {
    this.getCountryCode();
  }

  getCountryCode = async () => {
    try {
      const geoInfo = await getGeoInfoFromIp();
      this.setState({
        countryCode: geoInfo?.country_code,
      });
    } catch (error) {
      // Do nothing
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { location, pageContext: { locale }, data } = this.props;
    const { countryCode, loading } = this.state;
    const isSG = countryCode === countryCodes.SINGAPORE;

    let pageData = data.homePage.data;
    // if (isSG) {
    //   pageData = data.staffingFeaturePage.data;
    // }

    if (loading) return null;

    return (
      <Layout location={location} locale={locale} pageData={pageData}>
        <Seo
          title={`${pageData.meta_title} | Workmate`}
          description={pageData.meta_description}
          image={pageData.meta_image.url}
        />
        {isSG
          ? <FeatureHeader data={pageData} />
          : <HomeHeader data={pageData} />}
        <Slices slices={pageData.body} />
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query HomeQuery($localeKey: String) {
    staffingFeaturePage: prismicFeaturesIndividualPage(
      lang: { eq: $localeKey }
      uid: { eq: "staffing" }
    ) {
      data {
        feature_name
        navigation_cta_button_text
        navigation_cta_button_url
        meta_title
        meta_description
        meta_image {
          url
        }
        hero_title
        hero_description
        hero_image {
          alt
          gatsbyImageData
        }
        desktop_primary_button_text
        desktop_secondary_button_text
        mobile_primary_button_text
        button_url
        hubspot_form_id
        body {
          ... on PrismicFeaturesIndividualPageDataBodyLogos {
            id
            slice_type
            primary {
              title
              description
            }
            items {
              logo {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicFeaturesIndividualPageDataBodyFeatures {
            id
            slice_type
            primary {
              title
            }
            items {
              feature_name
              feature_title
              feature_description
              link_url
              link_text
              icon {
                url
                alt
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicFeaturesIndividualPageDataBodyFeaturesInGrid {
            id
            slice_type
            primary {
              title
              sub_title
            }
            items {
              icon {
                url
                alt
              }
              feature_title
              feature_description
              link_text
              link_url
            }
          }
          ... on PrismicFeaturesIndividualPageDataBodyCtaBanner {
            id
            slice_type
            primary {
              title
              description
              cta_button_url
              cta_desktop_primary_button_text
              cta_desktop_secondary_button_text
              cta_mobile_primary_button_text
              cta_hubspot_form_id
            }
          }
        }
      }
    }
    homePage: prismicHomePage(lang: { eq: $localeKey }) {
      data {
        hero_title
        hero_message
        navigation_cta_button_text
        navigation_cta_button_url
        meta_title
        meta_description
        meta_image {
          alt
          copyright
          url
        }
        desktop_primary_button_text
        mobile_primary_button_text
        button_url
        desktop_secondary_button_text
        hubspot_form_id
        main_wistia_video_id
        main_wistia_video_image {
          alt
          gatsbyImageData
        }
        title
        worker_caption
        worker_image {
          alt
          gatsbyImageData
        }
        worker_button_text
        business_caption
        business_image {
          alt
          gatsbyImageData
        }
        business_button_text
        body {
          ... on PrismicHomePageDataBodyLogos {
            id
            slice_type
            primary {
              title
              description
            }
            items {
              logo {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomePageDataBodyHighlightsWithImages {
            slice_type
            primary {
              image {
                url
                alt
              }
              title
              highlights_button_url
              highlights_button_text
            }
            items {
              title
              image {
                alt
                url
              }
              description
            }
          }
          ... on PrismicHomePageDataBodySlider {
            id
            slice_type
            primary {
              description
              title
            }
            items {
              image {
                alt
                url
              }
              link_text
              link_url
              section_main_description
              section_sub_description
              section_title
            }
          }
          ... on PrismicHomePageDataBodySliderWithBackground {
            slice_type
            items {
              background_image {
                alt
                url
              }
              button_text
              main_description
              sub_description
              title
              button_link {
                uid
              }
            }
          }
          ... on PrismicHomePageDataBodyCtaBanner {
            slice_type
            primary {
              cta_button_url
              title
              description
              cta_mobile_primary_button_text
              cta_hubspot_form_id
              cta_desktop_secondary_button_text
              cta_desktop_primary_button_text
            }
          }
        }
      }
    }
    prismicSite(lang: { eq: $localeKey }) {
      data {
        clients {
          logo {
            alt
            copyright
            url
          }
          name
        }
      }
    }
  }
`;
