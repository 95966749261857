import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { fontFG24l, fontFG52m } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Grid from "../../styles/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../shared/CTAButton";
import HubspotFormModal from "../shared/HubspotFormModal";
import WistiaVideo from "../shared/WistiaVideo";
import { withContext } from "../shared/withContext";
import { appendUtmContentToUrl } from "../utilities/utmUtils";

const MobileButton = styled(Button)`
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;
const DesktopButton = styled(Button)`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    margin: 0 8px 16px 8px;
    display: inline-block;
  }
`;
const ButtonRow = styled.div`
  margin-bottom: 24px;
`;
const Title = styled.h1`
  ${fontFG52m};
`;
const Description = styled.h5`
  ${fontFG24l};
  margin: 24px 0 40px 0;
`;

const WistiaVideoWrapper = styled.div``;

const VideoImage = styled.div`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  transition-delay: 1s;
`;

const VideoWrapper = styled.div`
  position: relative;
  margin: 40px 0;
  padding-bottom: 56.25%;

  ${VideoImage}, ${WistiaVideoWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const Container = styled(Grid)`
  padding-top: 68px;
  text-align: center;

  ${Description} {
    grid-column: 2 / span 4;
  }
  ${VideoWrapper} {
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 80px;

    ${Description} {
      grid-column: 5 / span 6;
    }
    ${VideoWrapper} {
      grid-column: 3 / span 10;
    }
  }
`;

class Header extends React.Component {
  state = {
    bookDemoModalOpen: false,
    showVideo: false,
    videoPopover: true,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.showVideo);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showVideo);
  }

  handleVideoImageClick = () => {
    this.setState({ videoPopover: false }, this.showVideo);
  };

  showVideo = () => {
    window.removeEventListener("scroll", this.showVideo);
    this.setState({
      showVideo: true,
    });
  };

  openBookDemoModal = () => {
    this.setState({ bookDemoModalOpen: true });
  };

  onCancelModal = () => {
    this.setState({ bookDemoModalOpen: false });
  };

  // onButtonClick = (buttonText) => {
  //   window.analytics.track(`Clicked ${buttonText}`, { label: "Home Hero" });
  // };

  render() {
    const {
      data,
      context: { utmContent },
    } = this.props;
    const { bookDemoModalOpen, showVideo, videoPopover } = this.state;

    let url = data.button_url;
    if (url && utmContent) {
      url = appendUtmContentToUrl(url, utmContent);
    }
    const mainWistiaVideoImageData = getImage(data.main_wistia_video_image);
    return (
      <Container>
        <Title>{data.hero_title}</Title>
        <Description>{data.hero_message}</Description>
        <ButtonRow>
          {data.desktop_primary_button_text && (
            <DesktopButton
              target="_blank"
              href={url || null}
              onClick={() => {
                // this.onButtonClick(data.desktop_primary_button_text);
                !url && this.openBookDemoModal();
              }}
            >
              {data.desktop_primary_button_text}
            </DesktopButton>
          )}
          {data.desktop_secondary_button_text && (
            <DesktopButton type="outline" onClick={this.openBookDemoModal}>
              {data.desktop_secondary_button_text}
            </DesktopButton>
          )}
          {data.mobile_primary_button_text && (
            <MobileButton
              target="_blank"
              href={url || null}
              onClick={() => {
                // this.onButtonClick(data.mobile_primary_button_text);
                !url && this.openBookDemoModal();
              }}
            >
              {data.mobile_primary_button_text}
            </MobileButton>
          )}
        </ButtonRow>
        {/* Hacky handling so that we can "lazily" load the wistia library only on user interaction */}
        <VideoWrapper onMouseEnter={this.showVideo} onClick={this.showVideo}>
          {showVideo && (
            <WistiaVideoWrapper>
              <WistiaVideo
                videoId={data.main_wistia_video_id}
                popover={videoPopover}
              />
            </WistiaVideoWrapper>
          )}
          <VideoImage onClick={this.handleVideoImageClick} visible={!showVideo}>
            {mainWistiaVideoImageData && (
              <GatsbyImage
                image={mainWistiaVideoImageData}
                alt={data.main_wistia_video_image.alt || ""}
              />
            )}
          </VideoImage>
        </VideoWrapper>
        <HubspotFormModal
          targetId="home_page_hubspot_form"
          formId={data.hubspot_form_id}
          visible={bookDemoModalOpen}
          onCancel={this.onCancelModal}
        />
      </Container>
    );
  }
}

export default withContext(Header);
